import { HTTP } from '../../axios/axios'
import urlSlug from 'url-slug'

async function getAll (lang, year) {
  try {
    const res = await HTTP.get('/news', { params: { limit: Number.MAX_SAFE_INTEGER, year }, headers: { 'accept-language': lang } })
    for (const item of res.data) {
      item.categoryId = Array.from(item.categories, i => i._id)
    }
    return res.data
  } catch (error) {
    console.log(error)
  }
}
async function addOne (news, lang) {
  news.lang = lang
  if (!news.createdAt) {
    news.createdAt = new Date()
  }
  delete news.categories
  news.slug = urlSlug(news.slug)
  return await HTTP.post('/news', news)
}
async function update (news, id) {
  delete news.categories
  delete news.Year
  news.slug = urlSlug(news.slug)
  return await HTTP.patch('/news/' + id, news)
}
async function deleteOne (news) {
  return await HTTP.delete('/news/' + news._id)
}
async function deleteMany (arr) {
  return await HTTP.post('/news/many', { _id: arr })
}

export default { getAll, deleteMany, addOne, update, deleteOne }
