<template>
  <div>
    <div class="flex flex-wrap justify-between">
      <label for="language"
        >Choose language:
        <select
          class="p-2 outline-none"
          v-model="lang"
          @change="getData(lang, year)"
          name="language"
        >
          <option value="en">English</option>
          <option value="ru">Russian</option>
          <option value="az">Azerbaijani</option>
        </select>
      </label>
      <label for="year"
        >Choose year:
        <select
          class="p-2 outline-none"
          v-model="year"
          @change="getData(lang, year)"
          name="year"
        >
          <option
            v-for="year in new Date().getFullYear() - 2009"
            :key="year"
            :value="2009 + year"
          >
            {{ 2009 + year }}
          </option>
        </select>
      </label>
    </div>
    <div class="p-4">
      <Table
        :lang="lang"
        :columnNames="['title', 'content', 'image', 'categories']"
        :keys="['title', 'categories', 'content', 'image', 'slug', 'createdAt']"
        @getData="getData(lang, year)"
        :requests="requests"
      />
    </div>
  </div>
</template>

<script>
import Table from '@/components/Table.vue'
import requests from '../services/requests/NewsRequests.js'

export default {
  data () {
    return {
      lang: 'en',
      year: new Date().getFullYear(),
      requests: requests
    }
  },
  name: 'News',
  components: {
    Table
  },
  methods: {
    // gets data from backend
    async getData (lang, year) {
      this.$emit('loading', true)
      const temp = await this.requests.getAll(lang, year)
      this.$store.commit('setData', temp)
      this.$emit('loading', false)
    }
  },
  created () {
    // fills table with data from backend on created
    this.getData(this.lang, this.year)
  }
}
</script>
